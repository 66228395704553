import React, { Component, ElementRef, ReactElement, ReactNode } from 'react';
import { render } from 'react-dom';
import './style.scss';
import { Controller, Scene } from 'react-scrollmagic';
import {
  Events,
  Link,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';

let index0 = {'--index':'0'};
let index1 = {'--index':'1'};
let index2 = {'--index':'2'};
let index3 = {'--index':'3'};

interface AppProps {}
interface AppState {
  name: string;
  ticking: boolean;
}

let scrollerRef: React.RefObject<HTMLOListElement>;

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      name: 'React',
      ticking: false,
    };

    let lastKnownScrollPosition = 0;

    scrollerRef = React.createRef();

    // Events.scrollEvent.register('begin', function (to, element) {
    //   console.log('begin', arguments, scrollerRef);
    // });

    // Events.scrollEvent.register('end', (to, element, yPos) => {
    //   //  window.innerHeight = 1000, panel4 marginTop = -200 (.05)
    //   this.updateScrollerPosition(yPos);
    // });

    document.addEventListener('scroll', (e) => {
      lastKnownScrollPosition = window.scrollY;

      if (!this.state.ticking) {
        window.requestAnimationFrame(() => {
          this.updateScrollerPosition(lastKnownScrollPosition);
          this.setState({
            ticking: false,
          });
        });
        this.setState({
          ticking: true,
        });
      }
    });

    scrollSpy.update();
  }

  updateScrollerPosition(yPos: number) {
    // let heightMultiple = window.innerHeight / 1000;
    // let magicNumber = 17;
    // scrollerRef.current.style.marginTop = `-${
    //   yPos / (magicNumber * heightMultiple)
    // }px`;

    let heightMultiple = window.innerHeight / 1000;
    let magicNumber = 85 - heightMultiple;
    if (scrollerRef.current === null) return;

    scrollerRef.current.style.marginTop = `-${
      yPos / (magicNumber * heightMultiple)
    }vh`;

    // scrollerRef.current.style.transform = `translateY(-${10+heightMultiple}%)`;
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  render() {
    let mainVideoUrl = './video_main.mp4';
    let altVideoUrl = './video_alt.mp4';
    if (window.innerWidth < 800) {
      mainVideoUrl = './video_main_mobile_noise.mp4';
      altVideoUrl = './video_alt_mobile_noise.mp4';
    }

    return (
      <div>
        <div className="logo">
          <img src="./logo.png" />
        </div>
        <div className="scroller">
          
          <ol ref={scrollerRef}>
            <li>
              <Link to="panel00" spy={true} smooth={true} duration={500}>
                00
              </Link>
            </li>
            <li>
              <Link to="panel01" spy={true} smooth={true} duration={500}>
                01
              </Link>
            </li>
            <li>
              <Link to="panel02" spy={true} smooth={true} duration={500}>
                02
              </Link>
            </li>
            <li>
              <Link to="panel03" spy={true} smooth={true} duration={500}>
                03
              </Link>
            </li>
            <li>
              <Link to="panel04" spy={true} smooth={true} duration={500}>
                04
              </Link>
            </li>
            <li>
              <Link to="panel05" spy={true} smooth={true} duration={500}>
                05
              </Link>
            </li>
          </ol>
        </div>
        <div id="trigger"></div>
        <Controller
          globalSceneOptions={{
            triggerHook: 'onLeave',
          }}
        >
          <Scene duration={200} classToggle="zap" triggerElement="#trigger">
            <div className="panel" id="panel00">
              <span className="scroller-active"></span>
              <div className="bg">
                <video
                  src={mainVideoUrl}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              </div>
              <div className="content">
                <span className="index">00</span>
                <h1>
                  <div className="stack">
                    {/*@ts-ignore*/}
                    <span style={index0}>
                      Developing high quality products and services for the
                      future
                    </span>
                    {/*@ts-ignore*/}
                    <span style={index1}>
                      Developing high quality products and services for the
                      future
                    </span>
                    {/*@ts-ignore*/}
                    <span style={index2}>
                      Developing high quality products and services for the
                      future
                    </span>
                  </div>
                </h1>
                <span className="explore">
                  <span className="link-text">    
                    <Link to="panel01" spy={true} smooth={true} duration={500}>
                    See Our Work &darr;
                    </Link>
                  </span>
                  <span className="scroller-link"></span>
                </span>
              </div>
            </div>
          </Scene>
          <Scene duration={200} offset={-100} classToggle="zap">
            <div className="panel blue" id="panel01">
              <span className="scroller-active"></span>
              <div className="bg">
                <video
                  src={altVideoUrl}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              </div>
              <div className="content">
                <span className="index">01</span>
                <h1>
                  <div className="stack">
                    {/*@ts-ignore*/}
                    <span style={index0}>Block Monitor</span>

                    {/*@ts-ignore*/}
                    <span style={index1}>Block Monitor</span>

                    {/*@ts-ignore*/}
                    <span style={index2}>Block Monitor</span>
                  </div>
                </h1>
                <h3>Active blockchain address balance monitoring</h3>
                <a
                  className="explore"
                  href="https://block-monitor.com/"
                  target="_blank"
                >
                  <span className="link-text">Explore ⧉</span>
                </a>
              </div>

              <div className="preview">
                <div className="preview-inner">
                  <img
                    src="./portfolio_blockmonitor.png"
                    alt="Block Monitor Screenshot"
                  />
                </div>
              </div>
            </div>
          </Scene>
          <Scene duration={200} offset={-100} classToggle="zap">
            <div className="panel turqoise" id="panel02">
              <span className="scroller-active"></span>
              <div className="bg">
                <video
                  src={altVideoUrl}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              </div>
              <div className="content">
                <span className="index">02</span>
                <h1>
                  <div className="stack">

                    {/*@ts-ignore*/}
                    <span style={index0}>ETHRank</span>

                    {/*@ts-ignore*/}
                    <span style={index1}>ETHRank</span>

                    {/*@ts-ignore*/}
                    <span style={index2}>ETHRank</span>
                  </div>
                </h1>
                <h3>An achievement system built on Ethereum</h3>
                <a
                  className="explore"
                  href="https://ethrank.io/"
                  target="_blank"
                >
                  <span className="link-text">Explore ⧉</span>
                </a>
              </div>
              <div className="preview">
                <div className="preview-inner">
                  <img
                    src="./portfolio_ethrank.png"
                    alt="ETHRank.io screenshot"
                  />
                </div>
              </div>
            </div>
          </Scene>
          <Scene duration={200} offset={-100} classToggle="zap">
            <div className="panel green" id="panel03">
              <span className="scroller-active"></span>
              <div className="bg">
                <video
                  src={altVideoUrl}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              </div>
              <div className="content">
                <span className="index">03</span>
                <h1>
                  <div className="stack">
                    {/*@ts-ignore*/}
                    <span style={index0}>Phoenix Wallet</span>

                    {/*@ts-ignore*/}
                    <span style={index1}>Phoenix Wallet</span>

                    {/*@ts-ignore*/}
                    <span style={index2}>Phoenix Wallet</span>
                  </div>
                </h1>
                <h3>An open source, cross platform Signum wallet</h3>
                <a
                  className="explore"
                  href="https://phoenix-wallet.rocks/"
                  target="_blank"
                >
                  <span className="link-text">Explore ⧉</span>
                </a>
              </div>
              <div className="preview">
                <div className="preview-inner">
                  <img
                    src="./portfolio_phoenix.png"
                    alt="Phoenix Wallet Screenshot"
                  />
                </div>
              </div>
            </div>
          </Scene>
          <Scene duration={200} offset={-100} classToggle="zap">
            <div className="panel turquoise" id="panel04">
              <span className="scroller-active"></span>
              <div className="bg">
                <video
                  src={mainVideoUrl}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              </div>
              <div className="content">
                <span className="index">04</span>
                <h1>
                  <div className="stack">
                    {/*@ts-ignore*/}
                    <span style={index0}>Ethereum Rookie Cards</span>

                    {/*@ts-ignore*/}
                    <span style={index1}>Ethereum Rookie Cards</span>

                    {/*@ts-ignore*/}
                    <span style={index2}>Ethereum Rookie Cards</span>
                  </div>
                </h1>
                <h3>The ultimate digital trading card collection</h3>
                <a
                  className="explore"
                  href="https://legends.erc-1.com/"
                  target="_blank"
                >
                  <span className="link-text">Explore ⧉</span>
                </a>
              </div>
              <div className="preview">
                <div className="preview-inner">
                  <img
                    src="./portfolio_erc1.png"
                    alt="ERC-1 Living Legends"
                  />
                </div>
              </div>
            </div>
          </Scene>
          <Scene duration={200} offset={-100} classToggle="zap">
            <div className="panel" id="panel05">
              <span className="scroller-active"></span>
              <div className="bg">
                <video
                  src={mainVideoUrl}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              </div>
              <div className="content">
                <h1>
                  <div className="stack">
                    {/*@ts-ignore*/}
                    <span style={index0}>
                      Let's build the future, together.
                    </span>
                    {/*@ts-ignore*/}
                    <span style={index1}>
                      Let's build the future, together.
                    </span>
                    {/*@ts-ignore*/}
                    <span style={index2}>
                      Let's build the future, together.
                    </span>
                  </div>
                </h1>
                <a
                  className="link-text"
                  href="https://twitter.com/exampLLC"
                  target="_blank"
                >
                  Contact Us Today <span>✉</span>
                </a>
                <address>
                  1401 21st Street
                  <br />
                  Suite R<br />
                  Sacramento, CA 95811
                </address>
              </div>
              <ul className="footer-social">
                <li className="twitter">
                  <a href="https://twitter.com/ExampLLC" target="_blank">
                    Twitter
                  </a>
                </li>
                <li className="discord">
                  <a href="https://discord.gg/CNVQWw6KFU">Discord</a>
                </li>
                <li className="telegram">
                  <a href="https://t.me/ExampLLC" target="_blank">
                    Telegram
                  </a>
                </li>
              </ul>
              <p className="copyright">
                All content copyright &copy; 2023 Examp, LLC. All Rights
                Reserved.
              </p>
            </div>
          </Scene>
        </Controller>
      </div>
    );
  }
}

render(<App />, document.getElementById('root'));
